// Data Explorer specific actions. No need to keep this in sync with the one in Portal.
export var Action;
(function (Action) {
    Action[Action["CollapseTreeNode"] = 0] = "CollapseTreeNode";
    Action[Action["CreateCollection"] = 1] = "CreateCollection";
    Action[Action["CreateDocument"] = 2] = "CreateDocument";
    Action[Action["CreateStoredProcedure"] = 3] = "CreateStoredProcedure";
    Action[Action["CreateTrigger"] = 4] = "CreateTrigger";
    Action[Action["CreateUDF"] = 5] = "CreateUDF";
    Action[Action["DeleteCollection"] = 6] = "DeleteCollection";
    Action[Action["DeleteDatabase"] = 7] = "DeleteDatabase";
    Action[Action["DeleteDocument"] = 8] = "DeleteDocument";
    Action[Action["ExpandTreeNode"] = 9] = "ExpandTreeNode";
    Action[Action["ExecuteQuery"] = 10] = "ExecuteQuery";
    Action[Action["HasFeature"] = 11] = "HasFeature";
    Action[Action["GetVNETServices"] = 12] = "GetVNETServices";
    Action[Action["InitializeAccountLocationFromResourceGroup"] = 13] = "InitializeAccountLocationFromResourceGroup";
    Action[Action["InitializeDataExplorer"] = 14] = "InitializeDataExplorer";
    Action[Action["LoadDatabaseAccount"] = 15] = "LoadDatabaseAccount";
    Action[Action["LoadCollections"] = 16] = "LoadCollections";
    Action[Action["LoadDatabases"] = 17] = "LoadDatabases";
    Action[Action["LoadOffers"] = 18] = "LoadOffers";
    Action[Action["MongoShell"] = 19] = "MongoShell";
    Action[Action["ContextualPane"] = 20] = "ContextualPane";
    Action[Action["ScaleThroughput"] = 21] = "ScaleThroughput";
    Action[Action["ToggleAutoscaleSetting"] = 22] = "ToggleAutoscaleSetting";
    Action[Action["SelectItem"] = 23] = "SelectItem";
    Action[Action["Tab"] = 24] = "Tab";
    Action[Action["UpdateDocument"] = 25] = "UpdateDocument";
    Action[Action["UpdateSettings"] = 26] = "UpdateSettings";
    Action[Action["UpdateStoredProcedure"] = 27] = "UpdateStoredProcedure";
    Action[Action["UpdateTrigger"] = 28] = "UpdateTrigger";
    Action[Action["UpdateUDF"] = 29] = "UpdateUDF";
    Action[Action["LoadResourceTree"] = 30] = "LoadResourceTree";
    Action[Action["CreateDatabase"] = 31] = "CreateDatabase";
    Action[Action["ResolveConflict"] = 32] = "ResolveConflict";
    Action[Action["DeleteConflict"] = 33] = "DeleteConflict";
    Action[Action["SaveQuery"] = 34] = "SaveQuery";
    Action[Action["SetupSavedQueries"] = 35] = "SetupSavedQueries";
    Action[Action["LoadSavedQuery"] = 36] = "LoadSavedQuery";
    Action[Action["DeleteSavedQuery"] = 37] = "DeleteSavedQuery";
    Action[Action["ConnectEncryptionToken"] = 38] = "ConnectEncryptionToken";
    Action[Action["SignInAad"] = 39] = "SignInAad";
    Action[Action["SignOutAad"] = 40] = "SignOutAad";
    Action[Action["FetchTenants"] = 41] = "FetchTenants";
    Action[Action["FetchSubscriptions"] = 42] = "FetchSubscriptions";
    Action[Action["FetchAccounts"] = 43] = "FetchAccounts";
    Action[Action["GetAccountKeys"] = 44] = "GetAccountKeys";
    Action[Action["LoadingStatus"] = 45] = "LoadingStatus";
    Action[Action["AccountSwitch"] = 46] = "AccountSwitch";
    Action[Action["SubscriptionSwitch"] = 47] = "SubscriptionSwitch";
    Action[Action["TenantSwitch"] = 48] = "TenantSwitch";
    Action[Action["DefaultTenantSwitch"] = 49] = "DefaultTenantSwitch";
    Action[Action["CreateNotebookWorkspace"] = 50] = "CreateNotebookWorkspace";
    Action[Action["NotebookErrorNotification"] = 51] = "NotebookErrorNotification";
    Action[Action["CreateSparkCluster"] = 52] = "CreateSparkCluster";
    Action[Action["UpdateSparkCluster"] = 53] = "UpdateSparkCluster";
    Action[Action["DeleteSparkCluster"] = 54] = "DeleteSparkCluster";
    Action[Action["LibraryManage"] = 55] = "LibraryManage";
    Action[Action["ClusterLibraryManage"] = 56] = "ClusterLibraryManage";
    Action[Action["ModifyOptionForThroughputWithSharedDatabase"] = 57] = "ModifyOptionForThroughputWithSharedDatabase";
    Action[Action["EnableAzureSynapseLink"] = 58] = "EnableAzureSynapseLink";
    Action[Action["CreateNewNotebook"] = 59] = "CreateNewNotebook";
    Action[Action["OpenSampleNotebook"] = 60] = "OpenSampleNotebook";
    Action[Action["ExecuteCell"] = 61] = "ExecuteCell";
    Action[Action["ExecuteCellPromptBtn"] = 62] = "ExecuteCellPromptBtn";
    Action[Action["ExecuteAllCells"] = 63] = "ExecuteAllCells";
    Action[Action["NotebookEnabled"] = 64] = "NotebookEnabled";
    Action[Action["NotebooksGitHubConnect"] = 65] = "NotebooksGitHubConnect";
    Action[Action["NotebooksGitHubAuthorize"] = 66] = "NotebooksGitHubAuthorize";
    Action[Action["NotebooksGitHubManualRepoAdd"] = 67] = "NotebooksGitHubManualRepoAdd";
    Action[Action["NotebooksGitHubManageRepo"] = 68] = "NotebooksGitHubManageRepo";
    Action[Action["NotebooksGitHubCommit"] = 69] = "NotebooksGitHubCommit";
    Action[Action["NotebooksGitHubDisconnect"] = 70] = "NotebooksGitHubDisconnect";
    Action[Action["NotebooksFetched"] = 71] = "NotebooksFetched";
    Action[Action["NotebooksKernelSpecName"] = 72] = "NotebooksKernelSpecName";
    Action[Action["NotebooksExecuteCellFromMenu"] = 73] = "NotebooksExecuteCellFromMenu";
    Action[Action["NotebooksClearOutputsFromMenu"] = 74] = "NotebooksClearOutputsFromMenu";
    Action[Action["NotebooksInsertCodeCellAboveFromMenu"] = 75] = "NotebooksInsertCodeCellAboveFromMenu";
    Action[Action["NotebooksInsertCodeCellBelowFromMenu"] = 76] = "NotebooksInsertCodeCellBelowFromMenu";
    Action[Action["NotebooksInsertTextCellAboveFromMenu"] = 77] = "NotebooksInsertTextCellAboveFromMenu";
    Action[Action["NotebooksInsertTextCellBelowFromMenu"] = 78] = "NotebooksInsertTextCellBelowFromMenu";
    Action[Action["NotebooksMoveCellUpFromMenu"] = 79] = "NotebooksMoveCellUpFromMenu";
    Action[Action["NotebooksMoveCellDownFromMenu"] = 80] = "NotebooksMoveCellDownFromMenu";
    Action[Action["PhoenixConnection"] = 81] = "PhoenixConnection";
    Action[Action["PhoenixHeartBeat"] = 82] = "PhoenixHeartBeat";
    Action[Action["PhoenixResetWorkspace"] = 83] = "PhoenixResetWorkspace";
    Action[Action["PhoenixDBAccountAllowed"] = 84] = "PhoenixDBAccountAllowed";
    Action[Action["DeleteCellFromMenu"] = 85] = "DeleteCellFromMenu";
    Action[Action["OpenTerminal"] = 86] = "OpenTerminal";
    Action[Action["CreateMongoCollectionWithWildcardIndex"] = 87] = "CreateMongoCollectionWithWildcardIndex";
    Action[Action["ClickCommandBarButton"] = 88] = "ClickCommandBarButton";
    Action[Action["RefreshResourceTreeMyNotebooks"] = 89] = "RefreshResourceTreeMyNotebooks";
    Action[Action["ClickResourceTreeNodeContextMenuItem"] = 90] = "ClickResourceTreeNodeContextMenuItem";
    Action[Action["DiscardSettings"] = 91] = "DiscardSettings";
    Action[Action["SettingsV2Updated"] = 92] = "SettingsV2Updated";
    Action[Action["SettingsV2Discarded"] = 93] = "SettingsV2Discarded";
    Action[Action["MongoIndexUpdated"] = 94] = "MongoIndexUpdated";
    Action[Action["NotebooksGalleryPublish"] = 95] = "NotebooksGalleryPublish";
    Action[Action["NotebooksGalleryReportAbuse"] = 96] = "NotebooksGalleryReportAbuse";
    Action[Action["NotebooksGalleryClickReportAbuse"] = 97] = "NotebooksGalleryClickReportAbuse";
    Action[Action["NotebooksGalleryViewCodeOfConduct"] = 98] = "NotebooksGalleryViewCodeOfConduct";
    Action[Action["NotebooksGalleryAcceptCodeOfConduct"] = 99] = "NotebooksGalleryAcceptCodeOfConduct";
    Action[Action["NotebooksGalleryFavorite"] = 100] = "NotebooksGalleryFavorite";
    Action[Action["NotebooksGalleryUnfavorite"] = 101] = "NotebooksGalleryUnfavorite";
    Action[Action["NotebooksGalleryClickDelete"] = 102] = "NotebooksGalleryClickDelete";
    Action[Action["NotebooksGalleryDelete"] = 103] = "NotebooksGalleryDelete";
    Action[Action["NotebooksGalleryClickDownload"] = 104] = "NotebooksGalleryClickDownload";
    Action[Action["NotebooksGalleryDownload"] = 105] = "NotebooksGalleryDownload";
    Action[Action["NotebooksGalleryViewNotebook"] = 106] = "NotebooksGalleryViewNotebook";
    Action[Action["NotebooksGalleryViewGallery"] = 107] = "NotebooksGalleryViewGallery";
    Action[Action["NotebooksGalleryViewOfficialSamples"] = 108] = "NotebooksGalleryViewOfficialSamples";
    Action[Action["NotebooksGalleryViewPublicGallery"] = 109] = "NotebooksGalleryViewPublicGallery";
    Action[Action["NotebooksGalleryViewFavorites"] = 110] = "NotebooksGalleryViewFavorites";
    Action[Action["NotebooksGalleryViewPublishedNotebooks"] = 111] = "NotebooksGalleryViewPublishedNotebooks";
    Action[Action["NotebooksGalleryClickPublishToGallery"] = 112] = "NotebooksGalleryClickPublishToGallery";
    Action[Action["NotebooksGalleryOfficialSamplesCount"] = 113] = "NotebooksGalleryOfficialSamplesCount";
    Action[Action["NotebooksGalleryPublicGalleryCount"] = 114] = "NotebooksGalleryPublicGalleryCount";
    Action[Action["NotebooksGalleryFavoritesCount"] = 115] = "NotebooksGalleryFavoritesCount";
    Action[Action["NotebooksGalleryPublishedCount"] = 116] = "NotebooksGalleryPublishedCount";
    Action[Action["SelfServe"] = 117] = "SelfServe";
    Action[Action["ExpandAddCollectionPaneAdvancedSection"] = 118] = "ExpandAddCollectionPaneAdvancedSection";
    Action[Action["SchemaAnalyzerClickAnalyze"] = 119] = "SchemaAnalyzerClickAnalyze";
    Action[Action["SelfServeComponent"] = 120] = "SelfServeComponent";
    Action[Action["LaunchQuickstart"] = 121] = "LaunchQuickstart";
    Action[Action["NewContainerHomepage"] = 122] = "NewContainerHomepage";
    Action[Action["Top3ItemsClicked"] = 123] = "Top3ItemsClicked";
    Action[Action["LearningResourcesClicked"] = 124] = "LearningResourcesClicked";
    Action[Action["PlayCarouselVideo"] = 125] = "PlayCarouselVideo";
    Action[Action["OpenCarousel"] = 126] = "OpenCarousel";
    Action[Action["CompleteCarousel"] = 127] = "CompleteCarousel";
    Action[Action["LaunchUITour"] = 128] = "LaunchUITour";
    Action[Action["CancelUITour"] = 129] = "CancelUITour";
    Action[Action["CompleteUITour"] = 130] = "CompleteUITour";
    Action[Action["OpenQueryCopilotFromSplashScreen"] = 131] = "OpenQueryCopilotFromSplashScreen";
    Action[Action["OpenQueryCopilotFromNewQuery"] = 132] = "OpenQueryCopilotFromNewQuery";
    Action[Action["ActivateQueryCopilot"] = 133] = "ActivateQueryCopilot";
    Action[Action["DeactivateQueryCopilot"] = 134] = "DeactivateQueryCopilot";
    Action[Action["QueryGenerationFromCopilotPrompt"] = 135] = "QueryGenerationFromCopilotPrompt";
    Action[Action["QueryEdited"] = 136] = "QueryEdited";
    Action[Action["ExecuteQueryGeneratedFromQueryCopilot"] = 137] = "ExecuteQueryGeneratedFromQueryCopilot";
    Action[Action["DeleteDocuments"] = 138] = "DeleteDocuments";
})(Action || (Action = {}));
export const ActionModifiers = {
    Start: "start",
    Success: "success",
    Failed: "failed",
    Mark: "mark",
    Open: "open",
    IFrameReady: "iframeready",
    Close: "close",
    Submit: "submit",
    IndexAll: "index all properties",
    NoIndex: "no indexing",
    Cancel: "cancel",
    ToggleAutoscaleOn: "autoscale on",
    ToggleAutoscaleOff: "autoscale off",
};
export var SourceBlade;
(function (SourceBlade) {
    SourceBlade[SourceBlade["AddCollection"] = 0] = "AddCollection";
    SourceBlade[SourceBlade["AzureFunction"] = 1] = "AzureFunction";
    SourceBlade[SourceBlade["BrowseCollectionBlade"] = 2] = "BrowseCollectionBlade";
    SourceBlade[SourceBlade["CassandraAccountCreateBlade"] = 3] = "CassandraAccountCreateBlade";
    SourceBlade[SourceBlade["CollectionSetting"] = 4] = "CollectionSetting";
    SourceBlade[SourceBlade["DatabaseAccountCreateBlade"] = 5] = "DatabaseAccountCreateBlade";
    SourceBlade[SourceBlade["DataExplorer"] = 6] = "DataExplorer";
    SourceBlade[SourceBlade["DeleteCollection"] = 7] = "DeleteCollection";
    SourceBlade[SourceBlade["DeleteDatabase"] = 8] = "DeleteDatabase";
    SourceBlade[SourceBlade["DocumentExplorer"] = 9] = "DocumentExplorer";
    SourceBlade[SourceBlade["FirewallVNETBlade"] = 10] = "FirewallVNETBlade";
    SourceBlade[SourceBlade["Metrics"] = 11] = "Metrics";
    SourceBlade[SourceBlade["NonDocumentDBAccountCreateBlade"] = 12] = "NonDocumentDBAccountCreateBlade";
    SourceBlade[SourceBlade["OverviewBlade"] = 13] = "OverviewBlade";
    SourceBlade[SourceBlade["QueryExplorer"] = 14] = "QueryExplorer";
    SourceBlade[SourceBlade["Quickstart"] = 15] = "Quickstart";
    SourceBlade[SourceBlade["ReaderWarning"] = 16] = "ReaderWarning";
    SourceBlade[SourceBlade["ResourceMenu"] = 17] = "ResourceMenu";
    SourceBlade[SourceBlade["RpcProvider"] = 18] = "RpcProvider";
    SourceBlade[SourceBlade["ScaleCollection"] = 19] = "ScaleCollection";
    SourceBlade[SourceBlade["ScriptExplorer"] = 20] = "ScriptExplorer";
    SourceBlade[SourceBlade["Keys"] = 21] = "Keys";
})(SourceBlade || (SourceBlade = {}));
export var Source;
(function (Source) {
    Source["ResourceTreeMenu"] = "ResourceTreeMenu";
    Source["CommandBarMenu"] = "CommandBarMenu";
})(Source || (Source = {}));
